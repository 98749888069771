<template>

    <div class="w-full h-full px-3 py-4 relative">

        <div class="h-full w-full rounded-lg shadow_box flex flex-col p-2 overflow-hidden" :class="'bg-module-'+mode">

            <All model="UserApp" :immediate="true" :query="query" ref="datauser" :data.sync="datauser" v-slot="{data,loading}"></All>

            <div class="h-16 flex flex-row">

                <div class="h-full w-12/12 flex flex-row">

                    <div class="h-full w-6/6 flex flex-col justify-center items-start px-2">
                        <span class="text-2xl font-semibold" :class="'text-dfont-'+mode">{{$t('relations')}}</span>
                        <span v-if="datauser" class="text-md font-semibold" :class="'text-dfont-'+mode">{{datauser.name}} <small>({{datauser.rol.Name}})</small></span>
                    </div>

                </div>

            </div>

            <div v-if="datauser && datauser.relation.length > 0" class="flex-1 overflow-auto">

                <user-relation v-for="(el,index) in datauser.relation" :key="index" :data="el" :rol="datauser.rol.Name" @deleted="deleted" @edit="edit"></user-relation>

            </div>

            <div v-if="datauser && datauser.relation.length==0" class="flex-1 flex flex-col">

                <div class="flex-1 flex flex-row justify-center items-center">

                    <span class="text-gray">- {{$t('addRelations')}} -</span>

                </div>

                <div class="h-40 w-1/2 rounded-full flex items-center justify-center">

                    <div class="flex flex-col justify-center items-center move -mt-8">
                        <i class="mdi mdi-chevron-down text-gray text-4xl -mb-10"></i>
                        <i class="mdi mdi-chevron-down text-gray text-4xl"></i>
                        <i class="mdi mdi-chevron-down text-gray text-4xl -mt-10"></i>
                    </div>

                    <div class="flex flex-col justify-center items-center move -mt-8">
                        <i class="mdi mdi-chevron-down text-gray text-4xl -mb-10"></i>
                        <i class="mdi mdi-chevron-down text-gray text-4xl"></i>
                        <i class="mdi mdi-chevron-down text-gray text-4xl -mt-10"></i>
                    </div>

                    <div class="flex flex-col justify-center items-center move -mt-8">
                        <i class="mdi mdi-chevron-down text-gray text-4xl -mb-10"></i>
                        <i class="mdi mdi-chevron-down text-gray text-4xl"></i>
                        <i class="mdi mdi-chevron-down text-gray text-4xl -mt-10"></i>
                    </div>

                </div>

            </div>

            <div v-if="!datauser" class="flex-1 relative">
                <loader :loading="loading"></loader>
            </div>

            <div v-if="datauser" class="h-8 w-full flex mt-6 flex-row">

                <div v-if="datauser.rol.Name != 'Gerente'" class="h-8 w-5/12 mx-auto bg-red rounded-lg flex flex-row justify-center items-center"  @click="openmodal = !openmodal">
                    <span class="text-white font-semibold">{{$t('addRelation')}}</span>
                </div>

                <div v-if="datauser.rol.Name == 'Gerente' && datauser.relation.length == 0" class="h-8 w-5/12 mx-auto bg-red rounded-lg flex flex-row justify-center items-center"  @click="openmodal = !openmodal">
                    <span class="text-white font-semibold">{{$t('addRelation')}}</span>
                </div>

                <div v-if="datauser.rol.Name == 'Gerente' && datauser.relation.length > 0" class="h-8 w-5/12 mx-auto bg-red_transparent rounded-lg flex flex-row justify-center items-center">
                    <span class="text-white font-semibold">{{$t('addRelation')}}</span>
                </div>

                <!-- <div v-if="datauser.rol.Name != 'Gerente'" class="h-8 w-5/12 mx-auto bg-good rounded-lg flex flex-row justify-center items-center"  @click="gotousers()">
                    <span class="text-white font-semibold">Lista Usuarios</span>
                </div> -->

                <div v-if="datauser.relation.length > 0" class="h-8 w-5/12 mx-auto bg-good rounded-lg flex flex-row justify-center items-center"  @click="gotousers()">
                    <span class="text-white font-semibold">{{$t('userList')}}</span>
                </div>

                <div v-if="datauser.relation.length == 0" class="h-8 w-5/12 mx-auto bg-good_transparent rounded-lg flex flex-row justify-center items-center">
                    <span class="text-white font-semibold">{{$t('userList')}}</span>
                </div>

            </div>

        </div>

        <div v-if="openmodal" class="h-full w-full z-20 absolute top-0 left-0 flex flex-col px-2 pt-2" :class="{'mt-0': slideModal, 'mt-160': !slideModal}" style="transition: .3s">

            <div class="h-full w-full bg-white rounded-lg shadow-panesa flex flex-col" :class="'bg-module-'+mode">

                <div class="h-16 flex flex-row">

                    <div class="h-full w-full flex flex-row p-2">

                        <div class="h-full w-full flex flex-row justify-between items-center px-2">
                            <span class="text-2xl font-semibold" :class="'text-dfont-'+mode">{{$t('addRelation')}}</span>
                            <i class="mdi mdi-close" :class="'text-dfont-'+mode" @click="openmodal = !openmodal"></i>
                        </div>

                    </div>

                </div>

                <div v-if="datauser" class="flex-1 px-2">

                    <!-- empresa -->
                    <div v-if="datauser.relation.length == 0 && datauser.rol.Name == 'Comercial'" class="h-auto px-2 mt-6 flex flex-col justify-center items-start">

                        <All model="BackCompany" :immediate="true" :query="querycompanies" :config="config" v-slot="{data:datacompany,loading:loadingcompany}">

                            <div v-if="!loadingcompany" class="h-auto w-full">

                                <span class="text-sm mb-2" :class="'text-dfont-'+mode">{{$t('company')}}</span>

                                <div class="h-8 w-full">

                                    <div class="h-full w-full rounded-lg flex flex-row justify-between items-center" :class="'text-dfont-'+mode+' bg-box-'+mode">

                                        <!-- <el-select v-model="companyselected" :placeholder="$t('selectACompany')" class="w-full">

                                            <el-option
                                            v-for="item in datacompany"
                                            :key="item.Id"
                                            :label="item.FrontName"
                                            :value="item.Id + ',' + item.IdLink"
                                            class="w-full">
                                            </el-option>

                                        </el-select> -->

                                        <select name="companyselected" v-model="companyselected" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                                            <option v-for="(el,index) in datacompany" :key="index" :value="el.Id + ',' + el.IdLink" :class="'text-dfont-'+mode">{{el.FrontName}}</option>
                                        </select>

                                    </div>

                                </div>

                            </div>

                        </All>

                    </div>

                    <div v-else-if="datauser.relation.length > 0 && datauser.rol.Name == 'Comercial'" class="h-auto px-2 mt-6 flex flex-col justify-center items-start">

                        <All model="BackCompany" :immediate="true" :query="querycompanies" :config="config" v-slot="{data:datacompany,loading:loadingcompany}">

                            <div v-if="!loadingcompany" class="h-auto w-full">

                                <span class="text-sm mb-2" :class="'text-dfont-'+mode">{{$t('company')}}</span>

                                <div class="h-8 w-full">

                                    <div class="h-full w-full rounded-lg flex flex-row justify-between items-center" :class="'text-dfont-'+mode+' bg-box-'+mode">

                                        <!-- <el-select v-model="companyselected" :placeholder="$t('selectACompany')" class="w-full">

                                            <el-option
                                            v-for="item in datacompany"
                                            v-if="item.Id == companyfixed"
                                            :key="item.Id"
                                            :label="item.FrontName"
                                            :value="item.Id + ',' + item.IdLink"
                                            class="w-full">
                                            </el-option>

                                        </el-select> -->

                                        <select name="companyselected" v-model="companyselected" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                                            <option v-for="(el,index) in datacompany" :key="index" v-if="el.Id == companyfixed" :value="el.Id + ',' + el.IdLink" :class="'text-dfont-'+mode">{{el.FrontName}}</option>
                                        </select>

                                    </div>

                                </div>

                            </div>

                        </All>

                    </div>

                    <div v-else class="h-auto px-2 mt-6 flex flex-col justify-center items-start">

                        <All model="BackCompany" :immediate="true" :query="querycompanies" :config="config" v-slot="{data:datacompany,loading:loadingcompany}">

                            <div v-if="!loadingcompany" class="h-auto w-full">

                                <span class="text-sm mb-2" :class="'text-dfont-'+mode">{{$t('company')}}</span>

                                <div class="h-8 w-full">

                                    <div class="h-full w-full rounded-lg flex flex-row justify-between items-center" :class="'text-dfont-'+mode+' bg-box-'+mode">

                                        <!-- <el-select v-model="companyselected" filterable :placeholder="$t('selectACompany')" class="w-full">

                                            <el-option
                                            v-for="item in datacompany"
                                            :key="item.Id"
                                            :label="item.FrontName"
                                            :value="item.Id + ',' + item.IdLink"
                                            class="w-full">
                                            </el-option>

                                        </el-select> -->

                                        <select name="companyselected" v-model="companyselected" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                                            <option v-for="(el,index) in datacompany" :key="index" :value="el.Id + ',' + el.IdLink" :class="'text-dfont-'+mode">{{el.FrontName}}</option>
                                        </select>

                                    </div>

                                </div>

                            </div>

                        </All>

                    </div>


                    <!-- IdSaletype -->
                    <div v-if="datauser.rol.Name == 'Comercial' && companyselected" class="h-auto px-2 mt-6 flex flex-col justify-center items-start">

                        <All model="BackSaleType" :immediate="true" :query="querycompanies" v-slot="{data:datacompany,loading:loadingcompany}" ref="saletypereff">

                            <div v-if="!loadingcompany" class="h-auto w-full">

                                <span class="text-sm mb-2" :class="'text-dfont-'+mode">Tipo de Venta</span>

                                <div class="h-8 w-full">

                                    <div class="h-full w-full rounded-lg flex flex-row justify-between items-center" :class="'text-dfont-'+mode+' bg-box-'+mode">

                                        <!-- <el-select v-model="saletype" filterable :placeholder="$t('sellingRate')" class="w-full text-dfont">

                                            <el-option
                                            v-for="item in datacompany"
                                            :key="item.Id"
                                            :label="item.Name"
                                            :value="item.Id"
                                            class="w-full text-dfont">
                                            </el-option>

                                        </el-select> -->

                                        <select name="saletype" v-model="saletype" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                                            <option v-for="(el,index) in datacompany" :key="index" :value="el.Id" :class="'text-dfont-'+mode">{{el.Name}}</option>
                                        </select>

                                    </div>

                                </div>

                            </div>

                        </All>

                    </div>

                    <!-- Zona -->
                    <div v-if="saletype != null" class="h-auto px-2 mt-6 flex flex-col justify-center items-start">

                        <All model="BackZone" :immediate="true" :query="queryzone" v-slot="{data:datazones,loading:loadinZones}" ref="zonereff">

                            <div v-if="!loadinZones" class="h-auto w-full">

                                <span class="text-sm mb-2" :class="'text-dfont-'+mode">Zona</span>

                                <div class="h-8 w-full">

                                    <div class="h-full w-full rounded-lg flex flex-row justify-between items-center" :class="'text-dfont-'+mode+' bg-box-'+mode">

                                        <!-- <el-select v-model="zone" filterable :placeholder="$t('zone')" class="w-full text-dfont">

                                            <el-option
                                            v-for="item in datazones[saletype.toUpperCase()]"
                                            :key="item"
                                            :label="item.zone.Name"
                                            :value="item.IdZoneLink"
                                            class="w-full text-dfont">
                                            </el-option>

                                        </el-select> -->

                                        <select name="zone" v-model="zone" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                                            <option v-for="(el,index) in datazones[saletype.toUpperCase()]" :key="index" :value="el.IdZoneLink" :class="'text-dfont-'+mode">{{el.zone.Name}}</option>
                                        </select>

                                    </div>

                                </div>

                            </div>

                        </All>

                    </div>

                    <!-- Employee -->
                    <div v-if="datauser.rol.Name == 'Representante' && companyselected" class="h-auto px-2 mt-6 flex flex-col justify-center items-start">

                        <All model="BackEmployee" :immediate="true" :query="queryzone" v-slot="{data:dataemployee,loading:loademployee}" ref="employeeref">

                            <div v-if="!loademployee" class="h-auto w-full">

                                <span class="text-sm mb-2" :class="'text-dfont-'+mode">Agente</span>

                                <div class="h-8 w-full">

                                    <div class="h-full w-full rounded-lg flex flex-row justify-between items-center" :class="'text-dfont-'+mode+' bg-box-'+mode">

                                        <!-- <el-select v-model="formRepresnetant.userRelation.IdEmployeeLink" filterable :placeholder="$t('selectAnAgent')" class="w-full text-dfont truncate">

                                            <el-option
                                            v-for="(item,index) in dataemployee"
                                            :key="item.IdEmployee+index"
                                            :label="item.employee.Name"
                                            :value="item.IdEmployeeLink"
                                            class="text-dfont w-10/12 ml-auto">
                                                <span class="truncate text-left">{{item.employee.Name}}</span>
                                            </el-option>

                                        </el-select> -->

                                        <select name="-" v-model="formRepresnetant.userRelation.IdEmployeeLink" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                                            <option v-for="(el,index) in dataemployee" :key="index" :value="el.IdEmployeeLink" :class="'text-dfont-'+mode">{{el.employee.Name}}</option>
                                        </select>

                                    </div>

                                </div>

                            </div>

                            <div v-else class="h-20 relative">
                                <loader :loading="loading"></loader>
                            </div>

                        </All>

                    </div>

                </div>

                <div class="h-8 w-full flex flex-row mb-2">

                    <div v-if="datauser && datauser.rol.Name == 'Gerente' && testGerente" class="h-8 mb-2 w-full">

                        <Request
                        model="AddRelation"
                        action="create"
                        :form="formGerente"
                        @success="onSuccess"
                        @error="onError"
                        v-slot="{ request, loading }" class="h-full w-6/12 mx-auto">

                            <div v-if="!loading" class="h-full w-6/12 mx-auto rounded-lg bg-red flex flex-row justify-center items-center" @click="request()">

                                <span class="text-white font-semibold">Añadir</span>

                            </div>

                        </Request>

                    </div>
                    <div v-if="datauser && datauser.rol.Name == 'Gerente' && !testGerente" class="h-8 mb-2 w-full">

                        <div v-if="!loading" class="h-full w-6/12 mx-auto rounded-lg bg-red_transparent flex flex-row justify-center items-center">

                            <span class="text-white font-semibold">Añadir</span>

                        </div>

                    </div>

                    <div v-if="datauser && datauser.rol.Name == 'Comercial' && testComercial" class="h-8 mb-2 w-full">

                        <Request
                        model="AddRelation"
                        action="create"
                        :form="formComercial"
                        @success="onSuccess"
                        @error="onError"
                        v-slot="{ request, loading }" class="h-full w-6/12 mx-auto">

                            <div v-if="!loading" class="h-full w-6/12 mx-auto rounded-lg bg-red flex flex-row justify-center items-center" @click="request()">

                                <span class="text-white font-semibold">Añadir</span>

                            </div>

                        </Request>

                    </div>
                    <div v-if="datauser && datauser.rol.Name == 'Comercial' && !testComercial" class="h-8 mb-2 w-full">

                        <div v-if="!loading" class="h-full w-6/12 mx-auto rounded-lg bg-red_transparent flex flex-row justify-center items-center">

                            <span class="text-white font-semibold">Añadir</span>

                        </div>

                    </div>

                    <div v-if="datauser && datauser.rol.Name == 'Representante' && testRepresentant" class="h-8 mb-2 w-full">

                        <Request
                        model="AddRelation"
                        action="create"
                        :form="formRepresnetant"
                        @success="onSuccess"
                        @error="onError"
                        v-slot="{ request, loading }" class="h-full w-6/12 mx-auto">

                            <div v-if="!loading" class="h-full w-6/12 mx-auto rounded-lg bg-red flex flex-row justify-center items-center" @click="request()">

                                <span class="text-white font-semibold">Añadir</span>

                            </div>

                        </Request>

                    </div>
                    <div v-if="datauser && datauser.rol.Name == 'Representante' && !testRepresentant" class="h-8 mb-2 w-full">

                        <div v-if="!loading" class="h-full w-6/12 mx-auto rounded-lg bg-red_transparent flex flex-row justify-center items-center">

                            <span class="text-white font-semibold">Añadir</span>

                        </div>

                    </div>

                </div>

            </div>

        </div>

    </div>

</template>

<script>
import basiclinechart from '../../components/basiclinechart.vue';
import { All, Request } from '@/api/components';
import { state, actions } from '@/store';
import userRelation from '../../components/userRelation.vue';
import loader from '../../components/loader.vue';

export default {
    components:{
        basiclinechart,
        All,
        Request,
        userRelation,
        loader
    },
    data(){
        return{
            rols:[
                'Propiedad',
                'Gerente',
                'Comercial',
                'Representante'
            ],
            form:{
                user:{
                    id: null,
                    name: '',
                    email: '',
                    surname1: '',
                    surname2: '',
                    IdType: null,
                    videoWall: false,
                    admin: 0
                }
            },
            form2:{
                userRelation:{
                    IdUser:null,
                    IdCompany: null,
                    IdCompanyLink: null,
                    IdSaletype: null,
                    IdZoneLink:null,
                    IdEmployeeLink: null
                }
            },
            formGerente:{
                userRelation:{
                    IdUser:null,
                    IdCompany:null,
                    IdCompanyLink:null
                }
            },
            formComercial:{
                userRelation:{
                    IdUser:null,
                    IdCompany:null,
                    IdCompanyLink:null,
                    IdSaleType: null,
                    IdZoneLink:null,
                }
            },
            formRepresnetant:{
                userRelation:{
                    IdUser:null,
                    IdCompany:null,
                    IdCompanyLink:null,
                    IdEmployeeLink:null
                }
            },
            datauser:null,
            openmodal:false,
            companyselected: null,
            slideModal:false,
            saletype: null,
            zone: null,
            employee: null,
            editmodal: false,
            slideModalEdit: false,
            relationedited: null,
            companyfixed: null
        }
    },
    methods:{
        async onSuccess(response){
            this.$message({
                message: 'Relación añadida',
                type: 'success'
            });
            this.companyfixed = response.IdCompany;
            this.$refs['datauser'].request();
            this.companyselected = null;
            this.saletype = null;
            this.zone = null;
            this.formRepresnetant.userRelation.IdEmployeeLink = null
            this.openmodal = !this.openmodal;

        },
        onError(error){
            this.$message.error('Error: ', error);
        },
        deleted(){
            this.$refs['datauser'].request();
            this.companyselected = null
            this.saletype = null
            this.zone = null
        },
        edit(id){

            for (let index = 0; index < this.datauser.relation.length; index++) {

                if(this.datauser.relation[index].Id == id){
                    this.relationedited = this.datauser.relation[index]
                }

            }

            this.editmodal = true

        }
    },
    computed:{
        query(){
            return{
                id: state.userToEdit
            }
        },
        querycompanies(){
            return{
                period: state.period,
                Dimension: state.dimensionGroup
            }
        },
        queryzone(){
            return{
                Company: this.form2.userRelation.IdCompany
            }
        },
        gotousers(){
            this.$router.push( {name:'users'} )
        },
        testGerente(){

            if(this.companyselected != null){
                return true
            } else {
                return false
            }

        },
        testComercial(){

            if(this.companyselected != null && this.saletype != null & this.zone != null){
                return true
            } else {
                return false
            }

        },
        testRepresentant(){

            if(this.companyselected != null && this.formRepresnetant.userRelation.IdEmployeeLink != null){
                return true
            } else{
                return false
            }

        },
        mode(){
            return state.mode
        }
    },
    watch:{
        datauser(n,o){

            this.form.user.id = n.id

            this.form2.userRelation.IdUser = n.id
            this.form2.userRelation.IdEmployeeLink = n.IdType

            this.formGerente.userRelation.IdUser = n.id

            this.formComercial.userRelation.IdUser = n.id

            this.formRepresnetant.userRelation.IdUser = n.id

            this.form.user.name = n.name
            this.form.user.email = n.email
            this.form.user.surname1 = n.surname1
            this.form.user.surname2 = n.surname2
            this.form.user.IdType = n.IdType
            this.form.user.videoWall = n.videoWall
            this.form.user.admin = n.admin

            if(n.relation.length<1){
                this.openmodal = !this.openmodal
            }

            if(n.relation.length > 0 && n.rol.Name == 'Comercial'){
                this.companyfixed = n.relation[0].IdCompany
            }

        },
        companyselected(n,o){

            this.form2.userRelation.IdCompany = n.split(',')[0]
            this.form2.userRelation.IdCompanyLink = n.split(',')[1]

            this.formGerente.userRelation.IdCompany = n.split(',')[0]
            this.formGerente.userRelation.IdCompanyLink = n.split(',')[1]

            this.formComercial.userRelation.IdCompany = n.split(',')[0]
            this.formComercial.userRelation.IdCompanyLink = n.split(',')[1]

            this.formRepresnetant.userRelation.IdCompany = n.split(',')[0]
            this.formRepresnetant.userRelation.IdCompanyLink = n.split(',')[1]

            this.zone = null

            if(this.datauser.rol.Name == 'Comercial'){

                this.$refs['saletypereff'].request();
                this.$refs['zonereff'].request();

            }

            if(this.datauser.rol.Name == 'Representante'){

                this.formRepresnetant.userRelation.IdEmployeeLink = null
                this.$refs['employeeref'].request();

            }

        },
        openmodal(n,o){

            setTimeout(() => {
                this.slideModal = !this.slideModal
            }, 100);

            this.companyselected = null;
            this.saletype = null;
            this.zone = null;
            this.formRepresnetant.userRelation.IdEmployeeLink = null

        },
        saletype(n,o){

            this.formComercial.userRelation.IdSaleType = n

            this.$refs['zonereff'].request();

        },
        zone(n,o){

            this.formComercial.userRelation.IdZoneLink = n

        },
        editmodal(){

            setTimeout(() => {
                this.slideModalEdit = !this.slideModalEdit
            }, 100);

        }
    }
}
</script>

<style scoped>

    .move{
        animation: todown infinite 1s ease-in-out alternate;
    }

    @keyframes todown{
        to{
            transform: translateY(60px);
        }
    }

</style>
