<template>

    <div class="h-auto rounded-lg flex flex-col mt-4 mx-1 overflow-hidden" :class="'bg-box-'+modetheme">
        <div class="h-8 flex flex-row justify-between items-center px-2" :class="{'bg-pamesa-whitemode': data.company.IdLink === 45 && modetheme == 'whitemode', 'bg-pamesa-darkmode': data.company.IdLink === 45 && modetheme == 'darkmode', 'bg-geotiles': data.company.IdLink === 28, 'bg-prissmacer': data.company.IdLink === 30,
                        'bg-ecoceramic': data.company.IdLink === 31, 'bg-dfont-whitemode': data.company.IdLink === 32 && modetheme == 'whitemode', 'bg-dfont-darkmode': data.company.IdLink === 32 && modetheme == 'darkmode', 'bg-tauceramica': data.company.IdLink === 36}">
            <span class="text-white text-lg font-semibold truncate">{{data.company.FrontName}}</span>
            <div class="h-full w-6 flex flex-row justify-center items-center" @click="mode = !mode">
                <i v-if="!mode" class="mdi mdi-dots-vertical text-white"></i>
                <i v-if="mode" class="mdi mdi-close text-white"></i>
            </div>
        </div>
        <div class="h-auto flex flex-col justify-center items-center px-2 py-2 relative">
            <div v-if="mode" class="h-full w-full absolute flex flex-row" :class="'bg-box-'+modetheme">
                <div class="h-full w-full flex flex-row justify-center items-center">
                    <Request
                    model="DeleteRelation"
                    action="delete"
                    :form="{id:data.Id}"
                    @success="onSuccess"
                    @error="onError"
                    v-slot="{ request, loading }">
                    <div v-if="!loading" class="h-8 w-6/12 bg-bad rounded-md flex flex-row justify-center items-center" @click="request()">
                        <span class="text-white text-lg font-semibold">{{$t('delete')}}</span>
                    </div>
                    </Request>
                </div>
            </div>
            <span v-if="rol==='Gerente'" class="text-md font-semibold" :class="'text-dfont-'+modetheme">{{rol}}</span>
            <span v-if="rol==='Comercial'" class="text-md font-semibold" :class="'text-dfont-'+modetheme">{{rol}} {{data.saletype.Name}}</span>
            <span v-if="rol==='Comercial'" class="text-lg font-semibold text-center" :class="'text-dfont-'+modetheme">{{$t('zone')}} {{data.zonelink.zone.Name}}</span>
            <span v-if="rol==='Representante'" class="text-md font-semibold" :class="'text-dfont-'+modetheme">{{rol}}</span>
            <span v-if="rol==='Representante'" class="text-md font-semibold" :class="'text-dfont-'+modetheme">{{data.employeelink.employee.Name}}</span>
        </div>
    </div>

</template>

<script>
import { All, Request } from '@/api/components';
import { state, actions } from '@/store';

export default {
    props:['data', 'rol'],
    components:{
        All,
        Request
    },
    data(){
        return {
            mode: false,
            id: null
        }
    },
    methods:{
        getcolor(company) {
            switch (company) {
                case 'PAMESA CERAMICA':
                    return 'bg-pamesa'
                    break;
                case 'TAU CERAMICA':
                    return 'bg-tauceramica'
                    break;
                case 'ECOCERAMIC':
                    return 'bg-ecoceramic'
                    break;
                case 'PRISSMACER':
                    return 'bg-prissmacer'
                    break;
                case 'GEOTILES':
                    return 'bg-geotiles'
                    break;
                case 'NAVARTI CERAMICA':
                    return 'bg-navarti'
                    break;
                default:
                    return 'bg-videowall_gray'
                break;
            }
        },
        async onSuccess() {
            this.mode = false
            this.$message({
                message: this.$t("relationDeleted"),
                type: 'success'
            });
            this.$emit('deleted')
        },
        onError() {
            this.$message.error('Error');
        },
        edit(id) {
            this.$emit('edit', id)
        }
    },
    computed:{
        modetheme(){
            return state.mode
        }
    }
}
</script>
